const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['domain'] = require('../middleware/domain.ts')
middleware['domain'] = middleware['domain'].default || middleware['domain']

middleware['page-change-tracker'] = require('../middleware/page-change-tracker.ts')
middleware['page-change-tracker'] = middleware['page-change-tracker'].default || middleware['page-change-tracker']

export default middleware
